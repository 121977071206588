/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import editBtn from "../../templates/images/edit-pen.svg";
import loading from "../../templates/images/misc/loading.gif";
import { Avatar } from "@material-ui/core";
import { useSelector } from "react-redux";
import { RootState } from "../../setup";
import { ASSEST_URL } from "../../app/helper/EnvVar";

type Props = {
  onChange: (e: React.ChangeEvent<any>) => void;
  name: string;
  isTouched: boolean;
  changeEvent?: boolean;
  setChangeEvent?: any;
  errorMsg: string | any;
  value?: string;
  show?:any;
  setShowCropper?: any;
  isLoading?: any;
  setIsLoading?: any;
  showCropperFor?: any;
  setShowCropperFor?: any;
  title: string;
  className: string;
  id: string;
  userProfile?: boolean | undefined;
  profileFile?: any;
  companyLogoFile?: any;
};

export const UploadFileNew: React.FC<Props> = ({
  onChange,
  value,
  name,
  isTouched,
  errorMsg,
  className,
  id,
  userProfile,
  isLoading,
  setShowCropper,
  showCropperFor,
  setShowCropperFor,
  changeEvent,
  setChangeEvent,
  profileFile,
}) => {
  const templateUserData = useSelector(
    (state: RootState) => state.templateUserData.templateUserData!
  );
  
  const onInputClick = (event: any) => {
    setChangeEvent(true)
    if(templateUserData?.profile_image?.filename_disk && showCropperFor === "profileImage" && changeEvent === false){
      event.preventDefault();
      setShowCropper(true);
      setShowCropperFor("profileImage")
      event.target.value = '';
    }else if(templateUserData?.company_logo?.filename_disk && showCropperFor === "companyLogo" && changeEvent === false){
      event.preventDefault();
      setShowCropper(true);
      setShowCropperFor("companyLogo")
      event.target.value = '';
    }else{
      onChange(event)
      setChangeEvent(false)
    }
  }

  return (
    <>
      <input
        type={"file"}
        className={className}
        id={id}
        name={name}
        onChange={(e) => {
          onChange(e)
        }}
        onClick={onInputClick}
        accept="image/*"
        value={value}
      />      
      {!userProfile ? (
        (name === "profile_image") ? <div className="d-flex flex-column align-items-center">
          <div className="upload-images">
            {isLoading ? 
              <Avatar src={loading} /> 
              : 
              <Avatar 
                src={templateUserData?.profile_image?.filename_disk ? `${ASSEST_URL}/${templateUserData?.profile_image?.filename_disk}` : profileFile} 
                style={{ width: "130px", height: "130px" }} 
              />
            }
          </div>
          <div className="edit-pen">
            <SVG src={editBtn} style={{ cursor: "pointer" }} />
          </div>
        </div> : <div className="d-flex flex-column align-items-center">
          <div className="upload-images">
          {isLoading ? <Avatar src={loading} /> : <Avatar  
              src={`${
                templateUserData?.company_logo?.filename_disk
                  ? `${ASSEST_URL}/${templateUserData?.company_logo?.filename_disk}`
                  : ""
                }`} 
              />}
          </div>
          <div className="edit-pen">
            <SVG src={editBtn} style={{ cursor: "pointer" }} />
          </div>
        </div>
      ) : (
        <SVG src={editBtn} style={{ cursor: "pointer" }} />
      )}
      {isTouched && errorMsg !== "" && (
        <div className="fv-plugins-message-container danger-validate">
          <div className="fv-help-block">{errorMsg}</div>
        </div>
      )}
    </>
  );
};
